.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  border-top-color: #3490dc; /* Customize the color as needed */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Sidebar active class css start */

.qrapid-sidebar a {
  color: #4c4c4c;
  border: 1px solid transparent;
  border-radius: 11.71px;
  padding: 15px 10px;
  margin: 0px 20px;
}
.qrapid-sidebar a:hover {
  border: 1px solid #4c4c4c;
}
.qrapid-sidebar a.active {
  color: #4c4c4c;
  background: #ffffff;
  border: 1px solid #16a34a;
}
/* Hide scrollbar in all browsers while keeping the content scrollable */
.hide-scrollbar {
  -ms-overflow-style: none;  /* For Internet Explorer */
  scrollbar-width: none;     /* For Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Safari and Chrome */
}

/* Sidebar active class css end */
